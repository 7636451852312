import { h } from 'preact';
import { Route, Router } from 'preact-router';
import { lazy, Suspense } from 'preact/compat';
import { Flip, ToastContainer } from 'react-toastify';
import Header from './header';

// import Seal from '../routes/seal';
// import Vip from '../routes/vip';
const Seal = lazy(() => import('../routes/seal'));
const Vip = lazy(() => import('../routes/vip'));

import 'react-toastify/dist/ReactToastify.css';
import {
  commonContext,
  useCommonReducer,
} from '../../src/hooks/useCommonState';

const App = () => {
  const [commonState, dispatchCommonState] = useCommonReducer();

  return (
    <div id="app">
      <commonContext.Provider value={{ commonState, dispatchCommonState }}>
        <Suspense
          fallback={
            <div
              style={{
                position: 'fixed',
                width: '100%',
                height: '100%',
                display: 'flex',
                top: '0px',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#ccc',
                fontSize: 30,
                fontWeight: 'bold',
              }}
            >
              加载中...
            </div>
          }
        >
          <Header />

          <Router>
            <Route path="/" component={Seal} />
            <Route path="/vip" component={Vip} />
            <Route path="/practice" component={Seal} />
            <Route path="/work" component={Seal} />
            <Route path="/income" component={Seal} />
          </Router>
        </Suspense>
      </commonContext.Provider>

      <ToastContainer
        position="top-right"
        transition={Flip}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        toastClassName="common-toast"
      />

      {/* <Box
        style={{
          position: 'fixed',
          left: '50%',
          transform: 'translateX(-50%)',
          opacity: 0.7,
          textAlign: 'center',
          bottom: '30px',
          color: 'rgb(95, 33, 32)',
          backgroundColor: 'rgb(253, 237, 237)',
          pointerEvents: 'none',
        }}
        sx={{
          padding: { xs: '10px 20px', md: '10px 60px' },
          width: { xs: '100%', sm: '800px' },
        }}
      >
        本网站所有信息仅供参考，不具有任何有效功能。 <br />
        涉及到交易或者服务的问题，本网站概不负责，亦不负任何法律责任。
      </Box> */}
    </div>
  );
};

export default App;
